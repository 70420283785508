@import 'sass/_typography';
@import 'sass/_devices';

.chips {
    margin-bottom: 8px;

    .email-input {
        @include desktop() {
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    .tag-container {
        overflow: hidden;
        overflow-y: auto;
        margin-bottom: 8px;
    }
    .tag-item {
        background-color: #d4d5d6;
        display: inline-block;
        font-size: 14px;
        border-radius: 30px;
        height: 30px;
        padding: 0 4px 0 1rem;
        display: inline-flex;
        align-items: center;
        margin: 0 0.3rem 0.3rem 0;
        button {
            background-color: white;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: none;
            cursor: pointer;
            font: inherit;
            margin-left: 10px;
            font-weight: bold;
            padding: 0;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .error {
        color: red;
    }
}
