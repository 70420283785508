@import 'sass/_typography';

.top-nav {
    min-height: 60px;
    background-color: var(--white);
    box-shadow: 0px 3px 6px var(--shadow);
}
.nav-drop-menu {
    display: flex;
    gap: 10px;
}

.nav-button {
    // @include setFont(18px, $SemiBold);
    text-align: left;
    margin-right: 25px;
    border-radius: 0;
    min-width: 0;
    height: inherit;
    color: var(--primary-dark);

    &.active {
        // @include setFont(18px, $SemiBold);
        border-bottom: 3px solid var(--secondary);
    }
}

.invite-button,
.add-tech-button {
    color: var(--primary);
    font-weight: $SemiBold;
    font-size: 16px;
    border: 2px solid var(--primary);
    border-radius: 5px;
    width: fit-content;
    padding: 0 10px;
    height: 35px;
    margin-top: 12px;
}

.add-tech-button {
    margin-right: 10px;
}

.top-nav-flex,
.nav-links {
    display: flex;
    flex-direction: row;
}

.nav-links {
    padding: 12px 0px 0px 0px;

    &.nav-links_button {
        padding: 12px 0px 0px 0px;
    }
}

.top-nav-flex {
    padding: 0 50px;
    justify-content: space-between;
}
// .modal-container {
//     @include desktop() {
//         max-width: 900px;
//         width: 900px;
//     }
// }
