@import 'sass/_typography';
@import 'sass/_devices';

.modal-container {
    max-width: 100vw;
    padding: 5px;
    @include desktop() {
        max-width: 500px;
    }
    .header {
        width: 100%;
        height: 30px;
        .closeButton {
            float: right;
            outline: none;
            min-width: unset;
        }
    }

    .title {
        @include setFont(14px, $SemiBold);

        @include desktop() {
            @include setFont(18px, $SemiBold);
        }
        margin-bottom: 0;
    }

    .footer {
        flex-wrap: wrap;
        flex-direction: column;

        @include desktop() {
            justify-content: space-evenly;
            flex-wrap: nowrap;
            flex-direction: row;
        }

        button {
            margin-bottom: 10px;
        }
    }
}
