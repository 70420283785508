@import 'sass/_typography';
@import 'sass/_devices';
@import 'sass/_utilities';

.mobile-nav-item-container {
    &.custom-icons {
        padding-right: 14px;
    }

    .mobile-nav-item {
        display: flex;
        flex-direction: row;
        margin: 1rem;
        padding-left: 1rem;
        align-items: center;

        &.with-content {
            margin-bottom: unset;
        }

        &.no-icon {
            margin: unset;
            margin-right: 1rem;
        }

        &.show-dividers {
            padding: 10px 0 8px 0;
            border-top: 1px solid #00000048;
            border-bottom: 1px solid #00000048;
        }

        .custom-icon {
            padding: 5px;
            margin: 3px 3px 3px 14px;
        }

        .burger-button {
            display: flex;
            padding: 0.5rem;
            margin-left: 0.5rem;
            margin-top: 3px;
            @include setFont(16px, $Medium);
        }

        .title-no-button {
            display: flex;
            padding: 0.5rem;
            margin-left: 0.5rem;
            margin-top: 5px;
            @include setFont(16px, $Medium);
            // color: #9e9e9e;
        }

        .menu-chevron {
            margin-top: 3px;
            margin-left: auto;
        }

        .missing-nav-item-icon {
            width: 35px;
        }
    }

    .nav-item-content {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        margin-left: 65px;

        .indented-items {
            width: 100%;
        }
    }
}
