@import 'sass/_devices';
@import 'sass/_typography';

.modal-container {
    display: flex;
    flex-direction: column;
    .title {
        margin-bottom: 15px;
    }

    .message-modal {
        width: 500px;
    }

    .title-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .qr-code-container {
            @include setFont(14px, $SemiBold);
            display: flex;
            align-items: center;
            color: inherit;
            .qr-code {
                width: 32px;
                margin-left: 5px;
            }
            @include desktop() {
                display: none;
            }
        }
        svg {
            height: 32px;
            width: 32px;
            margin-right: 10px;
        }
    }

    .main-wrapper {
        display: flex;
        justify-content: center;

        .email-modal-body {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: fit-content;
            @include desktop() {
                align-content: space-between;
            }
            .body-main {
                @include setFont(14px, $Medium);
                margin-bottom: 20px;
            }
            .button-submit,
            .button-cancel {
                @include setFont(16px, $SemiBold);
                margin-top: 8px;
                width: 100%;

                @include desktop() {
                    width: fit-content;
                }
            }
        }
    }
}
