@import 'sass/_typography';
@import 'sass/_devices';

.profile-page-layout {
    width: 100%;
    a {
        &.MuiButton-outlined {
            border: 1px solid rgba(46, 96, 195, 0.5);
        }

        &.MuiButton-contained {
            color: var(--white);
        }
    }

    li {
        text-decoration: none;
    }

    .section {
        padding: 1rem;
    }
}
