@import 'sass/_typography';
@import 'sass/_devices';
@import 'sass/_utilities';

.main-head {
    align-items: center;
    color: var(--white);
    grid-area: header;
    box-shadow: 0px 3px 6px #00000029;

    button:focus {
        outline: revert;
        border: 2px solid -webkit-focus-ring-color;
    }

    &.block {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid var(--alert);
        background-color: white;
        color: var(--alert);
    }

    .logo-link {
        text-decoration: none;
        border: none;
    }

    .mobile {
        i {
            font-size: 18px;
        }
    }

    .logo {
        width: 300px;
        min-width: 125px;
    }

    h3 {
        font-weight: 100;
    }

    .filler {
        flex: 1;
    }

    .fa-bars {
        cursor: pointer;
        font-size: 24px;
        padding: 20px;
    }

    .input {
        align-items: center;
        background-color: var(--white);
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        height: 24px;
        padding: 4px;
        width: 200px;

        .fa-search {
            color: var(--gray-45);
            font-size: 14px;
            width: 20px;
        }

        input {
            background-color: inherit !important;
            border: 0;
            color: var(--text-body);
            font-size: 14px;
            height: 24px;
            outline: none;
            width: 180px;
        }
    }
}

.login-button,
.logout-button {
    color: var(--white);
    background-color: var(--primary-dark);
    border: none;
    @include setFont(18px, $SemiBold);
}

.search-overlay {
    color: var(--black);
    .search-overlay-mask {
        z-index: 50;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 60px;
        left: 0;
        background: grey 0% 0% no-repeat padding-box;
        opacity: 0.5;
    }

    .search-overlay_content {
        position: absolute;
        width: 100%;
        min-height: 140px;
        top: 60px;
        left: 0;

        // height: 100%;
        // overflow: scroll;
        //padding-bottom: 50px;

        z-index: 50;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px var(--shadow);
        border-radius: 0px 0px 10px 10px;
    }
}
