@import 'sass/_typography';
@import 'sass/_devices';

.icon-container {
    display: flex;
    line-height: 1.5em;

    .icon {
        padding: 0 0 0 60px;
        margin-top: 14px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .icon-container_title {
            font-weight: $Medium;
        }

        .avatar {
            img {
                width: 40px;
                border-radius: 50%;
            }
        }

        position: relative;

        .overlay {
            position: absolute;
            // top: -5px;
            // left: -150px;
            top: 53px;
            right: -25px; // offset for profile
            min-height: 200px;
            min-width: 360px;
            background-color: white;
            border: 1px solid var(--primary);
            z-index: 33;
            color: var(--black);

            &.search {
                position: fixed;
                width: 100%;
                top: 0px;
                right: 0px;
                border: none;
                min-height: unset;
            }

            .overlay_top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 60px;
                padding: 5px 5px 5px 10px;
                .title {
                    color: var(--black);
                    .heading {
                        @include setFont(18px, $SemiBold);
                    }

                    .sub-heading {
                        @include setFont(14px, $Regular);
                    }
                }
            }
            .overlay_content {
                padding: 10px;
                border-top: 1px solid var(--primary);

                .nav-notifications {
                    @include setFont(16px);
                    padding: 0.175em;
                    min-height: 150px;
                    max-height: 600px;
                    overflow-y: auto;
                }
            }
        }
    }
}
