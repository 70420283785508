@import 'sass/_typography';
@import 'sass/_devices';

.page-container {
    // padding-bottom: 1em;
    // @include desktop() {
    //     margin: 30px 50px 0 50px;

    //     &.single-page {
    //         margin: 30px 300px 0 300px;
    //     }
    // }

    .page {
        @include setFont(14px, $SemiBold);
        color: var(--primary);
    }

    .page-container_title {
        margin: 5px 5px 0 5px;
        @include setFont(24px, $Bold);
        color: var(--primary-dark);
        cursor: default;
        min-height: 36px;
        display: flex;

        .MuiGrid-container {
            align-items: start;
            flex-wrap: nowrap;

            .MuiButton-root {
                margin-top: 0.5em;
            }
        }

        .back-button,
        .edit-button {
            color: var(--primary);
            font-size: 12px;
            padding-right: 10px;
        }
        .edit-button {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .page-container_body {
        padding: 0.125em;
    }
}
