@import 'sass/_typography';
@import 'sass/_devices';

.basic-card {
    border-radius: 10px;
    outline: none;
    border: 1px solid var(--primary-3);
    min-height: 115px;

    &.grey {
        background: var(--basic-card-background) 0% 0% no-repeat padding-box;
    }

    &.white {
        background: var(--white) 0% 0% no-repeat padding-box;
    }

    .card_image {
        max-width: 100px;

        .empty-avatar-container {
            background: #e8e8e8 0% 0% no-repeat padding-box;
            border: 1px solid var(--gray-48d);
            border-radius: 15%;
            display: flex;
            align-items: center;
            justify-content: center;

            .empty-avatar-logo {
                width: 56px; // per Andrew
                height: 64px; // per Andrew
                padding: 0.05em;

                &.white {
                    color: white;
                }
            }
        }

        img {
            width: 100px;
            height: 100px;
            background: transparent;
            border-radius: 15%;
        }
    }
    &.alert {
        border: 4px solid var(--alert);
    }

    // basic card with kebab layout

    .card-content-kebab {
        margin-left: 0.5em;
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
        align-self: center;

        .card-content_title {
            @include setFont(16px, $SemiBold);
        }

        &.card_content_without_avatar {
            margin-top: unset;
        }
    }
    .card-content {
        margin-left: 0.5em;
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
        align-self: center;

        .card-content_title {
            @include setFont(16px, $SemiBold);
        }

        &.card_content_without_avatar {
            margin-top: unset;
        }
    }
    .basic-card-kebab {
        display: flex;
        align-items: flex-start;
    }
}
