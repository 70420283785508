.footer-container {
    height: 50px;

    .footer-content {
        position: relative;
        color: var(--white);
        link: {
            color: var(--white);
        }
        top: 8px;

        .version {
            position: absolute;
            right: 20px;
            top: 7px;
            color: var(--white);
        }
        .feedback {
            left: 20px;
            top: 0px;
            font-weight: 400px;
        }
    }
}
