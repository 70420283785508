@import 'sass/_devices';

$afwerx-blue: #0f182d;

.LandingPage {
    overflow: initial;
    height: calc(100vh - 60px);

    a:hover {
        color: inherit;
    }

    .MuiLink-underlineAlways {
        text-decoration: underline;
    }

    .MuiButton-outlined {
        border-bottom: 1px solid;
    }

    .MuiTypography-h2 {
        font-size: 35px;
        font-weight: 900;
        margin-top: 50px;
        text-align: center;
        text-transform: uppercase;

        @include mobile() {
            font-size: 28px;
        }
    }

    .hero {
        background-image: url('./assets/AFPo-unregistered-landing-page-hero.png');
        background-position: center;
        background-size: cover;
        position: relative;

        @include mobile() {
            min-height: 471px;
        }

        &::after {
            content: '';
            background-color: $afwerx-blue;
            opacity: 0.7;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        & > * {
            position: relative;
            z-index: 1;
        }

        .hero-icon {
            @include mobile() {
                max-height: 50px;
                max-width: 50px;
            }
        }

        .hero-icon-circle {
            align-items: center;
            background-color: $afwerx-blue;
            border: 3px solid #fff;
            border-radius: 50%;
            display: flex;
            height: 109px;
            justify-content: center;
            margin-bottom: 20px;
            width: 109px;

            @include mobile() {
                height: 78px;
                width: 78px;
            }
        }
    }
}
