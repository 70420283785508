@import 'sass/_typography';
@import 'sass/_devices';
@import 'sass/_utilities';

.hamburger {
    button {
        svg {
            display: block;
            margin-right: 0.5em;
        }
    }
    //}
    @include desktop() {
        display: none;
    }
}

.menu-overlay {
    .menu-overlay-mask {
        z-index: 55;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: grey 0% 0% no-repeat padding-box;
        opacity: 0.5;
    }
    .menu-overlay_content {
        position: absolute;
        width: 95%;
        min-height: 100vh;
        height: 100%;
        // min-height: 140px;
        top: 0;
        left: 0;
        color: var(--black);
        @include setFont(12px, $SemiBold);
        z-index: 66;
        background: #ffffff 0% 0% no-repeat padding-box;

        .burger-top {
            display: flex;
            background: var(--primary-dark) 0% 0% no-repeat padding-box;
            height: 60px;
            color: white;
            box-shadow: var(--shadow) 0 3px 6px;

            .burger-top-label {
                align-self: center;
                font-size: 20px;
            }
        }

        .menu-content {
            overflow-y: scroll;
            height: 80vh;
        }
    }

    .mobile-nav-menu-dividers {
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        padding-top: 5px;
    }

    .mobile-nav-menu-bottom-divider {
        border-bottom: 1px solid lightgray;
        padding-top: 5px;

        .indented-container {
            .mobile-nav-item-container .nav-item-content {
                padding-right: 14px;
                display: block;
            }
        }
    }

    .organization-menu-item {
        .organization-menu-item_title {
            @include setFont(14px, $Medium);
        }
    }

    .profile-menu-item {
        .profile-menu-item_name {
            @include setFont(14px, $Medium);
        }

        .profile-menu-item_org {
            @include setFont(12px, $Medium);
        }

        .profile-menu-item_email {
            @include setFont(12px, $Medium);
        }

        .profile-menu-item_tel {
            @include setFont(12px, $Medium);
        }
    }
}
