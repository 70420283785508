@import 'sass/_typography';

.content-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    &.include-padding {
        padding: 1em 0 1em 0;
    }

    .content-area_title {
        @include setFont(16px, $SemiBold);
        color: var(--primary-dark);
        margin-bottom: 0.2em;
    }

    &.no-wrap {
        flex-wrap: nowrap;

        .content-area_content {
            margin-left: 1em;
            margin-top: 0.125em;
        }
    }

    .content-area_content {
        display: flex;
        @include setFont(14px, $Regular);
        flex-wrap: wrap;
        width: 100%;

        &.content-area_content_row {
            flex-direction: row;
        }

        &.content-area_content_column {
            flex-direction: column;
        }
    }

    &.with-bottom-shadow {
        /* box-shadow: 0 2px 2px -1px var(--shadow); */
        border-bottom: 0.75px solid var(--light-grey);
    }

    &.with-top-border {
        border-top: 0.75px solid var(--light-grey);
    }
}
