@import 'sass/_typography';
@import 'sass/_devices';

.add-discussion-page-container {
    display: flex;
    justify-content: center;

    h2 {
        @include setFont(16px, $Bold);
    }

    .button-panel {
        width: 100%;
    }
}
