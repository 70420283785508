@import 'sass/_typography';

/* autocomplete */
.autocomplete {
    width: 100%;
    /*the container must be positioned relative:*/
    position: relative;
    // display: inline-block;

    label {
        text-align: left;
        font-weight: var(--font-weight-semibold);
        letter-spacing: 0px;
    }
    .input-container {
        display: flex;
        align-content: flex-start;

        input {
            width: 100%;
            background: var(--white) 0% 0% no-repeat padding-box;
            border: 1px solid var(--gray-48);
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-right: unset;
            box-sizing: border-box;
            color: var(--text-light);
            height: 36px;
            outline: none;
            padding-left: 6px;
        }

        input:disabled {
            border: none;
        }

        .dropdown-indicator {
            outline: none;
            width: 36px;
            height: 36px;
            background: #387bb5 0% 0% no-repeat padding-box;
            border: 0.75px solid #abadb3;
            border-radius: 0px 3px 3px 0px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: var(--white);
                font-size: 1.5em;
            }

            svg {
                color: var(--white);
                font-size: 1.5em;
            }
        }
    }
}

.autocomplete .input-container .dropdown-indicator:hover {
    background: #458ece;
}

.autocomplete .input-container .dropdown-indicator:focus {
    background: #0e4f88;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid var(--autocomplete-border);
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;

    max-height: 250px;
    overflow-y: scroll;
}
.autocomplete-item {
    padding: 14px;
    cursor: pointer;
    background-color: var(--white);
    border-bottom: 1px solid var(--autocomplete-border);
    // @include setFont(14px, $Bold);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.suggestion-active {
        background-color: var(--hover-bg-color);
    }
}
.autocomplete-item:hover {
    /*when hovering an item:*/
    background-color: var(--hover-bg-color);
}
.autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background: var(--light-blue-grey);
    color: var(--white);
}

.org-auto-complete-result {
    min-height: 50px;
    padding-top: 5px;

    .avatar-container {
        background: 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 5px;
        opacity: 1;

        width: 30px;
        height: 30px;
    }

    .autocomplete-content {
        display: flex;
        flex-direction: column;
        margin-left: 18px;

        .autocomplete-content_organization {
            margin-top: 10px;
        }

        .autocomplete-content_content {
            font-size: 14px;
        }
        .autocomplete-content_address {
            font-size: 12px;
        }
    }
}
// complete result
