@import 'sass/_typography';

.panel {
    margin-top: 0.5em;
    .panel-heading {
        @include setFont(16px, $SemiBold);
        align-items: center;
        background: 0% 0% no-repeat padding-box;
        background-color: var(--white);
        border: 1px solid var(--primary-3);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        height: fit-content;
        min-height: 34px;
        outline: none;
        padding: 1em 0.5em;

        &.panel-no-collapse-heading {
            flex-direction: column;
            align-items: initial;
        }

        &.highlight {
            border-left: 1px solid var(--primary);
            border-top: 1px solid var(--primary);
            border-right: 1px solid var(--primary);
        }
        &.alert {
            border-left: 4px solid var(--alert);
            border-top: 4px solid var(--alert);
            border-right: 4px solid var(--alert);
        }
        &.panel-no-heading-divider {
            border-bottom: 0px solid var(--primary-3);
        }
        &.expanded {
            border-radius: 10px 10px 0px 0px;
        }
        .title {
            @include setFont(16px, $Bold);
            margin-left: 0.5em;
            color: var(--primary-dark);
        }
        .sub-title {
            @include setFont(14px);
            margin-left: 0.5em;
        }

        .panel-heading_content {
            display: flex;
            align-items: center;
            margin-right: 1em;
            margin-left: 1em;
            color: var(--primary-dark);
            font-weight: bold;
            svg {
                color: var(--primary-dark);
            }
        }
        .panel-no-collapse-heading_content {
            align-items: center;
            margin-right: 1em;
        }
    }

    .panel-body {
        outline: none;
        min-height: 50px;
        padding: 1em;
        background-color: var(--white);

        border: 1px solid var(--primary-3);
        &.highlight {
            border: 1px solid var(--primary);
            &.show-title {
                border-top: none;
            }
        }
        &.alert {
            border: 4px solid var(--alert);
        }
        border-radius: 10px;
        &.show-title {
            padding: 0.5em 1em;
            border-radius: 0px 0px 10px 10px;
            border-top: none;
        }
        display: flex;
        flex-direction: column;

        &.visible {
            display: block;
            .form-row:first-child {
                margin-top: 0;
            }
        }

        &.hidden {
            display: none;
            height: 0px;
        }

        &.has-footer {
            border-bottom: none;
            border-radius: unset;
        }
    }

    .panel-footer {
        padding: 1em;
        border: 1px solid var(--primary-3);
        background-color: var(--white);
        border-radius: 0px 0px 10px 10px;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
        height: 0px;
    }
}

.simple-panel {
    .simple-panel-heading {
        outline: none;
        background: 0% 0% no-repeat padding-box;
        border-radius: 10px;
        height: fit-content;
        min-height: 34px;
        margin-top: 0.5em;
        display: flex;
        align-items: center;

        @include setFont(14px, $SemiBold);

        &.expanded {
            border-radius: 10px 10px 0px 0px;
        }

        .simple-panel-heading_content {
            display: flex;
            align-items: center;

            &.treeview {
                padding: 0px;
                height: 16px;
            }
        }
    }

    .simple-panel-body {
        outline: none;
        min-height: 50px;
        padding: 1em 0 1em 0;
        border-top: none;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        flex-direction: column;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
        height: 0px;
    }
}
