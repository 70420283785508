.richTextEditor {
    ol {
        list-style-type: decimal;
        margin: 0;
    }
    ul {
        list-style-type: disc;
    }
    figure {
        display: inline;
    }

    .placeholder {
        color: #757575;
        cursor: text;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        left: 1.5rem;
        outline: none;
        position: absolute;
        top: 3.75rem;
    }
}
