@import 'sass/_typography';
@import 'sass/_devices';

[role='button'] {
    cursor: pointer;
}

.ReactModal__Overlay {
    z-index: 999; // changing to allow for dropdowns to appear on modals
}

.svg-inline--fa {
    &.fa-search {
        vertical-align: 1em;
    }
}

.required-label {
    color: #f44336;
}

.control-label {
    @include setFont(14px, $SemiBold);
}

li {
    text-decoration: dotted;
    margin-left: 20px;
}

// subsequent Breadcrumbs were out of place due to the li margin-left declaration
.MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-li:not(:first-child) {
        margin-left: 0px;
    }
}

.trash-icon {
    @include setFont(14px);
}

// used on profile pages...
.error-summary {
    .error-summary_message {
        color: var(--alert);
    }
}

// Default body styles
body {
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
}

#root {
    box-sizing: border-box;
    position: relative;
    padding-bottom: 50px; /* Height of footer */
    min-height: 100%;
    .root_content {
        @include mobile() {
            overflow-y: scroll;
        }
        min-height: calc(100vh - 110px); // for footer
    }

    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

button:focus {
    outline: none;
}
h1 {
    font-weight: $Bold;
    font-size: 18px;
    line-height: 2em;
}
h2 {
    font-weight: $SemiBold;
    font-size: 16px;
    line-height: 2em;
}

a {
    font-weight: $SemiBold;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pagination {
    list-style-type: none;
    list-style: none;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin: 1em 0 1.5em 0;

    ul.pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        li {
            text-transform: capitalize;

            a {
                font-size: 16px;
                margin: 0.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 24px;
            }
        }

        li.active {
            a {
                font-weight: bolder;
                border-radius: 15px;
            }
        }
    }
}

blockquote {
    border-left: 0.625rem solid var(--light-grey);
    margin: 0 0.625rem;
    padding: 0 0.625rem;
}

img {
    // Get rid of gap under images by making them display: inline-block; by default
    display: inline-block;
    vertical-align: middle;

    // Grid defaults to get images and embeds to work properly
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

// Make sure textarea takes on height automatically
textarea {
    height: auto;
    border-radius: $global-radius;
}

// Make select elements are 100% width by default
select {
    box-sizing: border-box;
    width: 100%;
    border-radius: $global-radius;
}

// Styles Google Maps and MapQuest embeds properly
// sass-lint:disable-line no-ids
.map_canvas,
.mqa-display {
    img,
    embed,
    object {
        max-width: none !important;
    }
}

// Reset <button> styles created by most browsers
button {
    padding: 0;
    appearance: none;
    border: 0;
    border-radius: $global-radius;
    background: transparent;
    line-height: 1;
    cursor: pointer;
}

// Prevent text overflow on pre
pre {
    overflow: auto;
}

// Make reset inherit font-family instead of settings sans-serif
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
}

// Internal classes to show/hide elements in JavaScript
.is-visible {
    display: block !important;
}

.is-hidden {
    display: none !important;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.double-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
}

.desktop {
    display: none;
    @include desktop() {
        display: block;
    }
}

.mobile {
    display: none;
    @include mobile() {
        display: block;
    }
}

// temporary external links
.link-item {
    margin-left: 1em;
}

.content-title {
    font-size: 14px;
    color: var(--primary-dark);
    font-weight: normal;
}

.added-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    .trash-icon {
        min-width: fit-content;
        margin: 0;
        padding: 0;
    }
}

.add-links-container {
    margin-bottom: 20px;
    align-items: flex-start;
    flex-wrap: wrap;
    @include desktop {
        flex-wrap: nowrap;
    }
    .url-container,
    .title-container {
        @include mobile {
            width: 100%;
            margin-right: 0;
        }
        button {
            padding: 0;
            cursor: default;
            font-size: var(--font-size-normal);
        }
    }
    .title-button-container {
        @include mobile {
            width: 100%;
            flex-wrap: wrap;
            margin-top: 5px;
        }
    }
    .button-container {
        width: 125px;
        min-width: fit-content;
    }
}

.add-link-button {
    width: 125px;
    min-width: fit-content;
    padding: 0;
}
