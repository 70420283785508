@import 'sass/_typography';

.step-button {
    background: var(--white) 0% 0% no-repeat padding-box;
    margin-bottom: 0.5em;
    @include setFont(14px, $SemiBold);

    &.with-padding {
        padding: 0.8em;
    }

    &.with-border {
        border: 1px solid var(--primary-3);
        border-radius: 8px;
    }

    &.required {
        border-color: var(--alert);
    }
}
